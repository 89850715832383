import React from 'react';
import { useState, useEffect } from "react"
import * as xlsx from "xlsx";
import Header from "../Header/Header";
import axiosConfig from "../axiosConfig";
import Select from 'react-select';

import * as XLSX from "xlsx";

import MainMenu from '../MainMenu/MainMenu';
var range;
var wholejson;
var comm;
const CategorialAudienceCount = () => {

  const [badf, setbadf] = useState(false);
  const [updater, setupdater] = useState(0);
  const [ranges, setranges] = useState([]);

  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  //get data from api "/getsystemuniverse" and Download
  useEffect(() => {
    axiosConfig.get("/lastCleanedDate").then((rsp) => {
      //alert(rsp.data.lastUpdatedDate);
      console.log(rsp.data);
      //setCleans(rsp.data.data);

      setLastUpdatedDate(rsp.data.lastCleanedDate);

    }, (err) => { });


  }, []);
  function getTodayAsString() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;
  }
  const getsystemuniverse = async () => {
    var data = {
      start: (start == "") ? getTodayAsString() : start,
      finish: (finish == "") ? getTodayAsString() : finish
    };
    console.log(data);
    const res = await axiosConfig.post("/getsystemuniverse", data);
    console.log(res.data);

    //download input
    axiosConfig.post("/entry/download", { operation_type: "download", affected: "CategorialAudienceCount", value: { type: "getsystemuniverse", input: data } }).then((rsp) => {

      Download(res.data.data);
    }, (err) => {
      //console.log(err);
      alert("Unable to download");
    });

  }
  const getsystemuniverseall = async () => {
    var data = {
      start: (start == "") ? getTodayAsString() : start,
      finish: (finish == "") ? getTodayAsString() : finish
    }
    const res = await axiosConfig.post("/getsystemuniverseall", data);
    console.log(res.data);

    //download input
    axiosConfig.post("/entry/download", { operation_type: "download", affected: "CategorialAudienceCount", value: { type: "getsystemuniverseall", input: data } }).then((rsp) => {

      Download(res.data.data);

    }, (err) => {
      //console.log(err);
      alert("Unable to download");
    });


  }
  const Download = (csv) => {


    const wb = XLSX.utils.book_new();

    var ws = XLSX.utils.aoa_to_sheet(csv);
    XLSX.utils.book_append_sheet(wb, ws, "Day Ranged");

    XLSX.writeFile(wb, "Data.xlsx");

  }

  return (
    <div><Header title="Categorial Audience Count" />
      <MainMenu menu="CategorialAudienceCount" />

      <div class="app-content content" style={{ backgroundColor: "azure", minHeight: "36em" }}>
        <div class="content-overlay"></div>
        <div class="content-wrapper" style={{ backgroundColor: "azure" }} >
          <div class="content-header row">
          </div>
          <div class="content-body">


            <div class="card">
              <div class="card-content">
                <div class="card-body">

                  <div class="row">
                    <div class="col-md-4 form-group">
                      <label for="startDate">Start Date:</label>
                      <input onChange={(e) => setStart(e.target.value)} type="date" id="startDate" class="form-control" />
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-4 form-group">
                      <label for="endDate">End Date:</label>
                      <input onChange={(e) => setFinish(e.target.value)} type="date" id="endDate" class="form-control" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="card-body">


                  <br />
                  <br />
                  <br />
                  <h1>Only Selected Audience</h1>

                  <div class="text-left">

                    <br />

                    <button disabled={start > finish || finish > lastUpdatedDate} onClick={getsystemuniverse} class="btn btn-info">Download</button>

                    <br />


                  </div>
                </div>

              </div>
            </div>


            <div class="card">
              <div class="card-content">
                <div class="card-body">


                  <br />
                  <br />
                  <br />
                  <h1>including Deselected Audience</h1>

                  <div class="text-left">

                    <br />

                    <button disabled={start > finish || finish > lastUpdatedDate} onClick={getsystemuniverseall} class="btn btn-info">Download</button>

                    <br />


                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

}
export default CategorialAudienceCount;