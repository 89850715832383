import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { useState, useEffect } from "react";
import axiosConfig from '../axiosConfig';
import TotalTimeSpentList, { Products } from "./TotalTimeSpentList";
import DailyTimeSpentList from "./DailyTimeSpentList";
import Select from 'react-select';
import Table from './Table';
import 'chartjs-adapter-date-fns';
import 'chartjs-adapter-moment';
import TimelineChart from './TimelineChart';
import Header from '../Header/Header';
import MainMenu from '../MainMenu/MainMenu';
import { set } from 'date-fns';




const UserDefined = () => {

    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState({});
    const [userName, setUserName] = useState("");
    const [start, setStart] = useState("");
    const [finish, setFinish] = useState("");
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("Please Select User and Time Frame");
    const [errorMsgTimeFrame, setErrorMsgTimeFrame] = useState("Start Time Must Be Less Than Finish Time");
    const [loadingUserInfo, setLoadingUserInfo] = useState(false);
    const [loadingTimeSpent, setLoadingTimeSpent] = useState(false);
    const [loading24hrChannelView, setLoading24hrChannelView] = useState(false);
    const [loading72hrChannelView, setLoading72hrChannelView] = useState(false);
    const [loadingAllTimeChannelView, setLoadingAllTimeChannelView] = useState(false);
    const [errorChannelData, setErrorChannelData] = useState("Error");
    const [erroralltime, setErroralltime] = useState("");
    const [errordaytime, setErrordaytime] = useState("");
    const [channeldaytime, setChanneldaytime] = useState([]);
    const [users, setUsers] = useState([]);
    const [channelalltime, setChannelalltime] = useState([]);
    const [timeSpentCSV, setTimeSpentCSV] = useState({});
    const [channelData, setChannelData] = useState({
        labels: [],
        datasets: []
    });
    const [last24hrData, setLast24hrData] = useState([]);
    let last24Array = [];
    const [allTimeData, setAllTimeData] = useState([]);
    let allTimeArray = [];
    const [handleSubmit, setHandleSubmit] = useState(false);





    useEffect(() => {

        axiosConfig.get("/getuserlist").then(rsp => {
            //console.log(rsp.data.users);
            setUsers(rsp.data.users);

        }).catch(err => {

        })

    }, [])



    const handleForm = (e) => {
        setHandleSubmit(!handleSubmit);
        e.preventDefault();
        var data = {
            user: user,
            start: start,
            finish: finish,
        };

        if (user && start && finish) {
            setLoadingUserInfo(false);
            setLoadingTimeSpent(false);
            setLoading24hrChannelView(false);
            setLoading72hrChannelView(false);
            setLoadingAllTimeChannelView(false);
            setErrorMsg(null);

            //input need only user
            axiosConfig.post("/user/userinfo", data).then(rsp => {
                setUserInfo(rsp.data.user);
                setLoadingUserInfo(true);
            }).catch(err => {
                setLoadingUserInfo(true);
            });


            axiosConfig.post("/user/userdaytimeviewlist", data).then(rsp => {
                setErrordaytime(rsp.data.error);
                setChanneldaytime(rsp.data.channels);
                for (var i = 0; i < rsp.data.channels.length; i++) {
                    if (rsp.data.channels[i].totaltime > 0) {
                        last24Array.push(rsp.data.channels[i]);
                    }
                }
                setLast24hrData(last24Array);
                setLoading24hrChannelView(true);

            }).catch(err => {
                setLoading24hrChannelView(true);
            });



            if (start < finish) {
                setErrorMsgTimeFrame(null);
                //// input need user, start, finish
                axiosConfig.post("/user/userdefined/usertimespent", data).then(rsp => {
                    //console.log(rsp.data);
                    setMsg(rsp.data.error);
                    setErrorChannelData("");
                    setChannelData(() => ({
                        labels: rsp.data.channels, datasets: [{
                            label: "Time Spent(min)", data: rsp.data.totaltime,
                            backgroundColor: ["#2a71d0"],
                            //borderColor: "black",
                            borderWidth: 1,
                            categoryPercentage: 0.9,
                            barPercentage: 1
                        }]
                    }));
                    setLoadingTimeSpent(true);
                    setTimeSpentCSV(() => ({
                        labels: rsp.data.channels, values: rsp.data.totaltime
                    }));
                }).catch(err => {
                    setLoadingTimeSpent(true);

                });
            } else {
                setErrorMsgTimeFrame("Start Time Must Be Less Than Finish Time");
            }


            axiosConfig.post("/user/useralltimeview", data).then(rsp => {
                setErroralltime(rsp.data.error);
                setChannelalltime(rsp.data.channels);
                for (var i = 0; i < rsp.data.channels.length; i++) {
                    if (rsp.data.channels[i].totaltime > 0) {
                        allTimeArray.push(rsp.data.channels[i]);
                    }
                }
                setAllTimeData(allTimeArray);
                setLoadingAllTimeChannelView(true);

            }).catch(err => {
                setLoadingAllTimeChannelView(true);
            });



        } else {
            setErrorMsg("Please Select User and Time Frame");
        }


    }




    function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                };
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };
        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    var getCSV = (scsv, user, username) => {
        var today = new Date(),
            datetime = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        exportToCsv(user + "_" + username + "-Time_Spent(" + datetime + ").csv", scsv)
    }

    const AllTogetherDownloadfunc = () => {

        //download input
        axiosConfig.post("/entry/download", { operation_type: "download", affected: "audience_profiles_user_defined", value: { type: "AllTogetherDownloadfunc", input: ["Channel", "Time Spent(min)/ (" + start + " To " + finish + ")", "Time Spent(min)/All Time Spent", "Time Spent(min)/Last 24hr"] } }).then((rsp) => {

            //console.log(liveChannelData.labels[0]);
            var csv = [["Channel", "Time Spent(min)/ (" + start + " To " + finish + ")", "Time Spent(min)/All Time Spent", "Time Spent(min)/Last 24hr"]];
            var sampleLive = timeSpentCSV;
            var sampleLive1 = channelalltime;
            var sampleLive2 = channeldaytime;
            for (var i = 0; i < sampleLive.labels.length; i++) {
                csv.push([sampleLive.labels[i], sampleLive.values[i], sampleLive1[i].totaltime, sampleLive2[i].totaltime]);
            }
            //console.log(csv);
            getCSV(csv, user, userName);
        }, (err) => {
            console.log(err);
            alert("Unable to download");
        });
    }
    const TimeSpentTimeFrameDownloadfunc = () => {
        //download input
        axiosConfig.post("/entry/download", { operation_type: "download", affected: "audience_profiles_user_defined", value: { type: "TimeSpentTimeFrameDownloadfunc", input: ["Channel", "Time Spent (min)/ (" + start + " To " + finish + ")"] } }).then((rsp) => {

            //console.log(liveChannelData.labels[0]);
            var csv = [["Channel", "Time Spent (min)/ (" + start + " To " + finish + ")"]];
            var sampleLive = timeSpentCSV;
            for (var i = 0; i < sampleLive.labels.length; i++) {
                csv.push([sampleLive.labels[i], sampleLive.values[i]]);
            }
            //console.log(csv);
            getCSV(csv, user, userName);
        }, (err) => {
            console.log(err);
            alert("Unable to download");
        });
    }
    const AlltimeDownloadfunc = () => {
        //download input
        axiosConfig.post("/entry/download", { operation_type: "download", affected: "audience_profiles_user_defined", value: { type: "AlltimeDownloadfunc", input: ["Channel", "Time Spent (min)/All Time Spent"] } }).then((rsp) => {

            //console.log(liveChannelData.labels[0]);
            var csv = [["Channel", "Time Spent (min)/All Time Spent"]];
            var sampleLive = channelalltime;
            for (var i = 0; i < sampleLive.length; i++) {
                csv.push([sampleLive[i].channel_name, sampleLive[i].totaltime]);
            }
            //console.log(csv);
            getCSV(csv, user, userName);
        }, (err) => {
            console.log(err);
            alert("Unable to download");
        });
    }
    const OneDayDownloadfunc = () => {
        //download input
        axiosConfig.post("/entry/download", { operation_type: "download", affected: "audience_profiles_user_defined", value: { type: "OneDayDownloadfunc", input: ["Channel", "Time Spent (min)/Last 24hr"] } }).then((rsp) => {

            //console.log(liveChannelData.labels[0]);
            var csv = [["Channel", "Time Spent (min)/Last 24hr"]];
            var sampleLive = channeldaytime;
            for (var i = 0; i < sampleLive.length; i++) {
                csv.push([sampleLive[i].channel_name, sampleLive[i].totaltime]);
            }
            //console.log(csv);
            getCSV(csv, user, userName);
        }, (err) => {
            console.log(err);
            alert("Unable to download");
        });
    }





    var start_string = new Date(start).toLocaleString(undefined, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
    var finish_string = new Date(finish).toLocaleString(undefined, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });


    return (
        <div><Header title="Audience Profile-User Defined" />
            <MainMenu menu="audiencedefined" />
            <div class="app-content content">
                <div class="content-overlay"></div>
                <div class="content-wrapper" style={{ backgroundColor: "azure" }} >
                    <div class="content-header row">
                    </div>
                    <div class="content-body">
                        <div class="card">
                            <div class="card-body">
                                <form onSubmit={handleForm}>
                                    <div class="row">

                                        <div class="col-3">
                                            <label for="dateTime1">User List</label>
                                            <Select
                                                placeholder="Select User"
                                                options={users.map(user => ({ label: user.user_name, value: user.id }))}
                                                onChange={opt => setUser(opt.value) & setUserName(opt.label)}
                                            />
                                        </div>
                                        <div class="row col-3">

                                            <fieldset class="form-group form-group-style">
                                                <label for="dateTime1">Start Time</label>
                                                <input type="datetime-local" class="form-control" id="dateTime1" step="1" onChange={(e) => { setStart(e.target.value) }} />
                                            </fieldset>
                                        </div>
                                        <div class="row col-3">
                                            <fieldset class="form-group form-group-style">
                                                <label for="dateTime1">Finish Time</label>
                                                <input type="datetime-local" class="form-control" id="dateTime1" step="1" onChange={(e) => { setFinish(e.target.value) }} />
                                            </fieldset>
                                        </div>

                                        <div class="col-1">
                                            <button type="submit" className="btn btn-success">View</button>
                                        </div>


                                        <div class="col-2">

                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-danger dropdown-toggle"
                                                    type="button"
                                                    id="menu1"
                                                    data-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Download
                                                    <span className="caret"></span>
                                                </button>
                                                <ul className="dropdown-menu" role="menu" aria-labelledby="menu1">
                                                    {/* Time Spent (Time Frame) */}
                                                    {errorChannelData !== "Error" && msg !== "Error" && (
                                                        <li role="presentation">
                                                            <button
                                                                type="button"
                                                                onClick={TimeSpentTimeFrameDownloadfunc}
                                                                className="btn btn-info btn-sm btn-block"
                                                                role="menuitem"
                                                            >
                                                                Time Spent (Time Frame)
                                                            </button>
                                                        </li>
                                                    )}

                                                    {/* Time Spent All Time */}
                                                    {channelalltime && (
                                                        <li role="presentation">
                                                            <button
                                                                type="button"
                                                                onClick={AlltimeDownloadfunc}
                                                                className="btn btn-info btn-sm btn-block"
                                                                role="menuitem"
                                                            >
                                                                Time Spent All Time
                                                            </button>
                                                        </li>
                                                    )}

                                                    {/* Time Spent 24 hr */}
                                                    {last24hrData.length > 0 && (
                                                        <li role="presentation">
                                                            <button
                                                                type="button"
                                                                onClick={OneDayDownloadfunc}
                                                                className="btn btn-info btn-sm btn-block"
                                                                role="menuitem"
                                                            >
                                                                Time Spent 24 hr
                                                            </button>
                                                        </li>
                                                    )}

                                                    {/* All In One */}
                                                    {errorChannelData !== "Error" && msg !== "Error" && channelalltime && (
                                                        <li role="presentation">
                                                            <button
                                                                type="button"
                                                                onClick={AllTogetherDownloadfunc}
                                                                className="btn btn-info btn-sm btn-block"
                                                                role="menuitem"
                                                            >
                                                                All In One
                                                            </button>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>

                        {(() => {

                            if (errorMsg == null) {
                                if (loadingUserInfo) {
                                    if (user !== "") {
                                        if (userInfo) {
                                            return <table class="table table-bordered " style={{ backgroundColor: "#FFFF" }}>
                                                <tr>
                                                    <th>User Name</th>
                                                    <th>Household Name</th>
                                                    <th>Device Box ID</th>
                                                    <th>Gender</th>
                                                    <th>Age</th>
                                                    <th>Economic Status</th>
                                                </tr>
                                                <tr>
                                                    <td>{userInfo.user_name}</td>
                                                    <td>{userInfo.device_name}</td>
                                                    <td>{userInfo.box_id ?? <span className='text-danger'>No Box Connected</span>}</td>
                                                    <td>{userInfo.gender}</td>
                                                    <td>{userInfo.age}</td>
                                                    <td>{userInfo.economic_status}</td>
                                                </tr>
                                            </table>
                                        }

                                    }
                                } else {
                                    return <div class="card">
                                        <div class="card-header">
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />
                                        </div>
                                    </div>
                                }

                            }
                        })()}

                        <br />


                        <div class="row justify-content-md-center">
                            <div class="col">

                                {(() => {

                                    if (errorMsg == null && errorMsgTimeFrame == null) {

                                        if (loadingTimeSpent) {


                                            if (errorChannelData === "Error" || msg === "Error") {
                                                return <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Time Spent</h4>
                                                        <h4><span class="danger">Please Select User & Time Frame</span></h4>
                                                    </div>
                                                </div>


                                            } else {
                                                return <div class="card">
                                                    <div class="card-header">
                                                        <div class="row"><div class="col-6 h2 card-title font-weight-bold">Time Spent (minute)</div><div class="row col h2 card-title text-left">From [<p class="text-primary bold"> {start_string}</p>] to [<p class="text-primary bold">{finish_string}</p>] </div></div>

                                                    </div>
                                                    <div class="card-content collapse show">
                                                        <div>
                                                            <Bar
                                                                data={channelData}
                                                                options={{
                                                                    title: {
                                                                        display: true,
                                                                        text: "Channels",
                                                                        fontSize: 20
                                                                    },
                                                                    scales: {
                                                                        y: {
                                                                            beginAtZero: true,
                                                                            ticks: {
                                                                                // Include a dollar sign in the ticks
                                                                                callback: function (value, index, ticks) {
                                                                                    return value + ' min';
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    legend: {
                                                                        display: true,
                                                                        position: 'right'
                                                                    }, plugins: {
                                                                        legend: {
                                                                            display: false  //remove if want to show label 
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>

                                            }
                                        } else {
                                            return <div class="card">
                                                <div class="card-header">
                                                    <div class="card-title">Time Spent</div>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />
                                                </div>
                                            </div>
                                        }

                                    } else {
                                        return <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Time Spent</h4>
                                                <h4><span class="danger">Please Select User & Time Frame (Start Time Must Be Less Than Finish Time)</span></h4>
                                            </div>
                                        </div>
                                    }
                                })()}


                            </div>
                        </div>

                        <br />

                        <div class="row justify-content-md-center">
                            <div class="col" >

                                {(() => {
                                    if (user === "") {
                                        return <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Watch History Of Last 24 Hours</h4>
                                                <h4><span class="danger">Please Select User To See Last 24 Hour Data</span></h4>
                                            </div>
                                        </div>


                                    } else {
                                        return <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Watch History Of Last 24 Hours</h4>
                                            </div>
                                            <div class="card-content collapse show">

                                                <TimelineChart class="w-100" user={user} errorMsg={errorMsg} handleSubmit={handleSubmit} url="/user/last24WatchingData" time="24" />


                                            </div>
                                        </div>

                                    }
                                })()}

                            </div>
                        </div>



                        <br />

                        <div class="row justify-content-md-center">
                            <div class="col" >

                                {(() => {
                                    if (user === "") {
                                        return <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Watch History Of Last 72 Hours</h4>
                                                <h4><span class="danger">Please Select User To See Last 72 Hour Data</span></h4>
                                            </div>
                                        </div>


                                    } else {
                                        return <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Watch History Of Last 72 Hours</h4>
                                            </div>
                                            <div class="card-content collapse show">

                                                <TimelineChart class="w-100" user={user} errorMsg={errorMsg} handleSubmit={handleSubmit} url="/user/last72WatchingData" time="72" />


                                            </div>
                                        </div>

                                    }
                                })()}

                            </div>
                        </div>



                        <br />

                        <div class="row match-height">
                            <div class="col-xl-6  col-12">
                                {(() => {
                                    if (errorMsg == null) {
                                        if (loadingAllTimeChannelView) {
                                            if (channelalltime) {
                                                return <Table title="All Time Channel Views" channels={allTimeData} error={erroralltime} />

                                            } else {
                                                return <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title"><span>All Time Channel Views</span></h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <h4 ><span class="danger">Please Select User To Show The Table</span></h4>
                                                    </div>
                                                </div>

                                            }
                                        } else {
                                            return <div class="card">
                                                <div class="card-header">
                                                    <div class="card-title"><span>All Time Channel Views</span></div>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />
                                                </div>
                                            </div>
                                        }

                                    } else {
                                        return <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title"><span>All Time Channel Views</span></h4>
                                            </div>
                                            <div class="card-body">
                                                <h4 ><span class="danger">Please Select User To Show The Table</span></h4>
                                            </div>
                                        </div>
                                    }

                                })()}
                            </div>
                            <div class="col-xl-6 col-12">
                                {(() => {
                                    if (errorMsg == null) {
                                        if (loadingAllTimeChannelView) {
                                            if (channeldaytime) {
                                                if (last24hrData.length > 0) {
                                                    return <Table title="Last 24 Hour Channel Views" channels={last24hrData} error={errordaytime} />
                                                } else {
                                                    return <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title"><span >Last 24 Hour Channel Views</span></h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <h4 ><span class="danger">No Data Available For Last 24 hr</span></h4>
                                                        </div>
                                                    </div>
                                                }


                                            } else {
                                                return <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title"><span >Last 24 Hour Channel Views</span></h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <h4 ><span class="danger">Please Select User To Show The Table</span></h4>
                                                    </div>
                                                </div>

                                            }

                                        } else {
                                            return <div class="card">
                                                <div class="card-header">
                                                    <div class="card-title"><span>Last 24 Hour Channel Views</span></div>
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" class="rounded mx-auto d-block" alt="..." />
                                                </div>
                                            </div>
                                        }

                                    } else {
                                        return <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title"><span>Last 24 Hour Channel Views</span></h4>
                                            </div>
                                            <div class="card-body">
                                                <h4 ><span class="danger">Please Select User To Show The Table</span></h4>
                                            </div>
                                        </div>
                                    }
                                })()}
                                {/* <DailyTimeSpentList /> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
export default UserDefined;